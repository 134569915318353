import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { filesize } from "filesize";
import { DateTime } from "luxon";
import React, { ReactElement, SyntheticEvent, useEffect, useState } from "react";

import {
  AnalysisJob,
  AnalysisTask,
  CustomError,
  RichValue,
  RichValueType,
  dataService,
} from "../services/data.service";
import ErrorAlert from "./common/ErrorAlert";

interface JobDetailProps {
  job?: AnalysisTask;
  handleClose: () => void;
}

const JobDetail = ({ job, handleClose }: JobDetailProps): ReactElement => {
  const [jobDetail, setJobDetail] = useState<AnalysisTask>();
  const [tab, setTab] = useState<string>("0");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<CustomError>();

  useEffect(() => {
    const fetchJob = async () => {
      if (job?.id) {
        setLoading(true);
        const response = await dataService.getJob(job.id);
        if (response.data) {
          setJobDetail(response.data);
        } else {
          setError(response.error);
        }
        setLoading(false);
      }
    };
    resetPanel();
    fetchJob();
  }, [job]);

  const resetPanel = () => {
    setJobDetail(undefined);
    setError(undefined);
    setTab("0");
  };

  const handleTabChange = (e: SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  const renderS3ObjectLink = ({
    type,
    value,
    url,
    fileSizeBytes,
    expiresAt,
  }: RichValue): ReactElement | null => {
    if (
      !jobDetail ||
      type !== RichValueType.S3_OBJECT ||
      !url ||
      !fileSizeBytes ||
      !expiresAt
    )
      return null;
    // Strip unnecessary information from S3 path
    const linkText = value.includes(jobDetail.id)
      ? value.substring(value.indexOf(jobDetail.id) + jobDetail.id.length + 1)
      : value;
    const fileSize = filesize(fileSizeBytes, { standard: "jedec" });
    const expiryLabel = `Link expires ${DateTime.fromISO(expiresAt).toRelative()}`;
    return (
      <>
        <Link href={url} target="_blank">
          {linkText}
        </Link>
        <br />
        <Typography variant="caption">
          {fileSize} &bull; {expiryLabel}
        </Typography>
      </>
    );
  };

  const renderRichValue = (richValue: RichValue): ReactElement | null => {
    const { type, value } = richValue;
    switch (type) {
      case RichValueType.PASS_FAIL:
        return (
          <Chip
            label={value}
            size="small"
            color={["Pass", "Success"].includes(value) ? "success" : "error"}
          ></Chip>
        );
      case RichValueType.PERCENTAGE:
      case RichValueType.FLOAT:
        return <Chip label={value} size="small"></Chip>;
      case RichValueType.LINK:
        return <Link href={value}>{value}</Link>;
      case RichValueType.S3_OBJECT:
        return renderS3ObjectLink(richValue);
      case RichValueType.TEXT:
      default:
        return <>{value}</>;
    }
  };

  const ProgressChip = ({ result, startedAt, finishedAt }: AnalysisJob): ReactElement => {
    const hasResult: boolean = result !== null && finishedAt !== null;
    const chipLabel: string = !hasResult
      ? "Started " + DateTime.fromISO(startedAt).toRelative()
      : "Completed in " +
        DateTime.fromISO(finishedAt)
          .diff(DateTime.fromISO(startedAt))
          .rescale()
          .get("minute") +
        " minutes";
    return (
      <Chip
        size="small"
        variant="outlined"
        label={chipLabel}
        color={hasResult ? "success" : "default"}
        icon={hasResult ? <CheckIcon /> : <ScheduleIcon />}
      />
    );
  };

  return (
    <Drawer open={!!job} anchor="right" onClose={handleClose}>
      <Box p={2} sx={{ textAlign: "right" }}>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box px={3} sx={{ fontSize: 12, width: 600 }}>
        {!!job && (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="h3" variant="h5">
              Job {job.sequenceNumber}
            </Typography>
            <Typography variant="body2" style={{ userSelect: "all" }} color="gray">
              {job.id}
            </Typography>
          </Stack>
        )}
        {loading && <LinearProgress sx={{ mt: 2 }} />}
        {error && (
          <Box mt={4}>
            <ErrorAlert {...error} />
          </Box>
        )}
        {!loading && !error && jobDetail && (
          <>
            <Typography variant="subtitle1" mb={2}>
              Created {DateTime.fromISO(jobDetail.requestedAt).toRelative()}
            </Typography>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList variant="fullWidth" onChange={handleTabChange}>
                  <Tab
                    value="0"
                    label={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <span>Results</span>
                        <Chip label={jobDetail.jobs.length} size="small" />
                      </Stack>
                    }
                  />
                  <Tab value="1" label="Sample Details" />
                </TabList>
              </Box>

              {/* RESULTS */}
              <TabPanel value="0" sx={{ px: 0 }}>
                {jobDetail.jobs.length === 0 && (
                  <Alert severity="info">
                    <AlertTitle>No analysis jobs have started yet.</AlertTitle>
                    More details will appear as the jobs start, this can take up to 3
                    minutes.
                  </Alert>
                )}
                {jobDetail.jobs.map((job: AnalysisJob) => (
                  <Box key={job.id} mb={5}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography component="h4" variant="h6">
                        {job.name}
                      </Typography>
                      <ProgressChip {...job} />
                    </Stack>
                    {job.result !== null ? (
                      <Table>
                        <TableBody>
                          {Object.entries(job.result).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell sx={{ textTransform: "capitalize", pl: 0 }}>
                                {key}
                              </TableCell>
                              <TableCell sx={{ wordBreak: "break-word" }}>
                                {renderRichValue(value)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Box mt={2}>
                        <Alert severity="info">
                          <AlertTitle>
                            This job has started, but has not posted results yet.
                          </AlertTitle>
                          Results will appear here when the job finishes.
                        </Alert>
                      </Box>
                    )}
                  </Box>
                ))}
              </TabPanel>

              {/* SAMPLE DETAILS */}
              <TabPanel value="1" sx={{ px: 0 }}>
                <Table>
                  <TableBody>
                    {Object.entries(jobDetail.sampleDetails).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell sx={{ textTransform: "capitalize", pl: 0 }}>
                          {key}
                        </TableCell>
                        <TableCell sx={{ wordBreak: "break-word" }}>{value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {Object.entries(jobDetail.sampleDetails).length !== 0 ? (
                  <Typography mt={1} color="gray" variant="body2">
                    This information is taken from the uploaded worksheet for this job.
                  </Typography>
                ) : (
                  <Typography mt={1} color="gray" variant="body2">
                    This sample does not have any sample details.
                  </Typography>
                )}
              </TabPanel>
            </TabContext>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default JobDetail;
