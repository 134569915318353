import styled from "@emotion/styled";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  MaterialDesignContent,
  SnackbarProvider,
  SnackbarProviderProps,
} from "notistack";
import * as React from "react";

import Dashboard from "./components/Dashboard";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e3b53",
    },
    success: {
      main: "#3f7d20",
    },
  },
});

// https://notistack.com/features/customization#override-styles
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.success.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.dark,
  },
}));

const snackbarOptions: SnackbarProviderProps = {
  anchorOrigin: { vertical: "top", horizontal: "center" },
  autoHideDuration: 3000,
  Components: {
    success: StyledMaterialDesignContent,
    error: StyledMaterialDesignContent,
  },
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider {...snackbarOptions}>
        <Dashboard />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
