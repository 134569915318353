import { Chip, ChipProps } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { DateTime } from "luxon";
import React, { ReactElement, useState } from "react";

import {
  AnalysisJob,
  AnalysisTask,
  JobType,
  WorkflowJobStatus,
} from "../services/data.service";
import JobDetail from "./JobDetail";
import CustomNoRowsOverlay from "./table/CustomNoRowsOverlay";

interface JobListProps {
  loading: boolean;
  rows: AnalysisTask[];
}

const statusChipColour: Record<WorkflowJobStatus, ChipProps> = {
  [WorkflowJobStatus.Pending]: { color: "default" },
  [WorkflowJobStatus.Started]: { color: "primary" },
  [WorkflowJobStatus.Complete]: { color: "success" },
  [WorkflowJobStatus.Failed]: { color: "error" },
};

const getJobStatus = (job?: AnalysisJob): WorkflowJobStatus => {
  if (job) {
    const { startedAt, finishedAt } = job;
    if (startedAt !== null && finishedAt === null) {
      return WorkflowJobStatus.Started;
    } else if (startedAt !== null && finishedAt !== null) {
      return WorkflowJobStatus.Complete;
    } else {
      return WorkflowJobStatus.Failed;
    }
  } else {
    return WorkflowJobStatus.Pending;
  }
};

const getChipProps = (params: GridRenderCellParams, jobType: JobType): ChipProps => {
  const job: AnalysisJob | undefined = params.row.jobs.find(
    (job: AnalysisJob) => job.jobType === jobType
  );
  const jobStatus = getJobStatus(job);
  return {
    size: "small",
    label: jobStatus,
    ...statusChipColour[jobStatus],
  };
};

const getTaskProgress = (params: GridValueGetterParams): string => {
  const completeCount = params.row.jobs.filter(
    (job: AnalysisJob) => job.finishedAt !== null
  ).length;
  return `${completeCount}/3 Jobs Complete`;
};

const columns: GridColDef[] = [
  {
    field: "sequenceNumber",
    headerName: "ID",
    width: 100,
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "requestedAt",
    headerName: "Created",

    width: 150,
    valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
  },
  { field: "initiatedBy", headerName: "Initiated By", width: 300 },
  { field: "pipelineName", headerName: "Pipeline", width: 150 },
  {
    field: "methylseqStatus",
    headerName: "Pre-Processing",
    width: 120,
    renderCell: (params) => <Chip {...getChipProps(params, JobType.PreProcessing)} />,
  },
  {
    field: "analysisAStatus",
    headerName: "QC",
    width: 120,
    renderCell: (params) => <Chip {...getChipProps(params, JobType.QualityControl)} />,
  },
  {
    field: "analysisBStatus",
    headerName: "Inference",
    width: 120,
    renderCell: (params) => <Chip {...getChipProps(params, JobType.Inference)} />,
  },
  {
    field: "progress",
    headerName: "Overall",
    width: 150,
    valueGetter: (params) => getTaskProgress(params),
  },
];

const CustomGridToolbar = (): ReactElement => {
  return (
    <GridToolbarContainer sx={{ p: 1 }}>
      <GridToolbarFilterButton sx={{ p: 1 }} />
    </GridToolbarContainer>
  );
};

const JobList = ({ rows, loading }: JobListProps): ReactElement => {
  const [expandedRow, setExpandedRow] = useState<AnalysisTask>();

  const handleClose = (): void => setExpandedRow(undefined);

  return (
    <>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        loading={loading}
        checkboxSelection
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "requestedAt", sort: "desc" }],
          },
        }}
        onRowClick={(params: GridRowParams) => {
          setExpandedRow(params.row as AnalysisTask);
        }}
        sx={{
          "--DataGrid-overlayHeight": "300px",
          ".MuiDataGrid-cell:focus": { outline: "none" },
          "& .MuiDataGrid-row:hover": { cursor: "pointer" },
        }}
        slots={{
          toolbar: CustomGridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
      <JobDetail job={expandedRow} handleClose={handleClose} />
    </>
  );
};

export default JobList;
