import { useAuthenticator } from "@aws-amplify/ui-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, Button, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { ReactElement, useEffect, useState } from "react";

import logo from "../assets/Cyted_logo_white_RGB_frameless.png";
import { AnalysisTask, CustomError, dataService } from "../services/data.service";
import JobList from "./JobList";
import UploadWorksheet from "./UploadWorksheet";
import ErrorAlert from "./common/ErrorAlert";

const Dashboard = (): ReactElement => {
  const [rows, setRows] = useState<AnalysisTask[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<CustomError>();

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    setError(undefined);
    setLoading(true);
    const response = await dataService.getJobs();
    if (response.data) {
      setRows(response.data);
    } else {
      setError(response.error);
    }
    setLoading(false);
  };

  return (
    <Box>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <Box src={logo} component="img" sx={{ height: 24, mr: 3 }} />
          <Typography variant="button" component="div" sx={{ flexGrow: 1 }}>
            Molecular Pipeline Manager
          </Typography>
          <Avatar sx={{ width: 28, height: 28 }}>{user.username[0].toUpperCase()}</Avatar>
          <Button
            color="inherit"
            sx={{ ml: 2 }}
            startIcon={<LogoutIcon />}
            onClick={signOut}
          >
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="xl" sx={{ my: 4 }}>
          <Stack mb={3} direction="row" justifyContent="space-between">
            <Typography component="h1" variant="h5">
              Workflow jobs
            </Typography>
            {!error && <UploadWorksheet fetchJobs={fetchJobs} />}
          </Stack>

          <Grid container>
            <Grid item xs={12}>
              {error ? (
                <ErrorAlert {...error} />
              ) : (
                <Paper>
                  <JobList loading={loading} rows={rows} />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
