import { Alert, AlertTitle } from "@mui/material";
import React, { ReactElement } from "react";

import { CustomError } from "../../services/data.service";

const ErrorAlert = ({ error, msg }: CustomError): ReactElement => {
  return (
    <Alert severity="error">
      <AlertTitle>{error}</AlertTitle>
      {msg}
    </Alert>
  );
};

export default ErrorAlert;
