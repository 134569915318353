import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { ReactElement, useRef, useState } from "react";

import { dataService } from "../services/data.service";

interface UploadWorksheetProps {
  fetchJobs: () => Promise<void>;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadWorksheet = ({ fetchJobs }: UploadWorksheetProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [busy, setBusy] = useState<boolean>(false);

  const handleFileChange = async (): Promise<void> => {
    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const formData = new FormData();
      formData.append("worksheet", fileInput.files[0]);
      setBusy(true);
      const response = await dataService.uploadWorksheet(formData);
      if (response.data) {
        enqueueSnackbar("Upload successful", { variant: "success" });
        await fetchJobs();
      } else {
        enqueueSnackbar(response.error?.msg ?? "Upload failed", { variant: "error" });
      }
      setBusy(false);
      fileInput.value = "";
    }
  };

  // https://mui.com/material-ui/react-button/#file-upload
  return (
    <LoadingButton
      loading={busy}
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
    >
      Upload worksheet
      <VisuallyHiddenInput
        type="file"
        accept=".csv"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </LoadingButton>
  );
};

export default UploadWorksheet;
