import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosInstance, AxiosResponse, isAxiosError } from "axios";

export interface CustomError {
  error: string;
  msg: string;
}

export enum WorkflowJobStatus {
  Pending = "Pending",
  Started = "Started",
  Complete = "Complete",
  Failed = "Failed",
}

export enum JobType {
  QualityControl = "QualityControl",
  Inference = "Inference",
  PreProcessing = "PreProcessing",
}

export enum RichValueType {
  TEXT = "TEXT",
  PASS_FAIL = "PASS_FAIL",
  PERCENTAGE = "PERCENTAGE",
  FLOAT = "FLOAT",
  LINK = "LINK",
  S3_OBJECT = "S3_OBJECT",
}

export interface RichValue {
  type: RichValueType;
  value: string;
  url?: string;
  fileSizeBytes?: number;
  expiresAt?: string;
}

export interface WorksheetReference {
  filename: string;
}

export interface AnalysisTask {
  batchId: string;
  id: string;
  initiatedBy: string;
  jobs: AnalysisJob[];
  pipelineName: string;
  requestedAt: string;
  sampleDetails: Record<string, string>;
  sequenceNumber: number;
  sourceWorksheet: WorksheetReference;
  taskDefinitionId: string;
}

export interface AnalysisJob {
  id: string;
  task: string;
  jobType: JobType;
  startedAt: string;
  finishedAt: string;
  name: string;
  result: Record<string, RichValue>;
}

const getRestApi = (): AxiosInstance => {
  const restApi = axios.create({
    // Use Vite server proxy for /api requests in local dev
    baseURL: import.meta.env.VITE_APP_BACKEND_URL ?? "/api",
  });
  restApi.interceptors.request.use((config) => {
    // fetchAuthSession() refreshes tokens with Cognito if needed
    return fetchAuthSession()
      .then((session) => {
        config.headers.Authorization = `Bearer ${session.tokens?.idToken?.toString()}`;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  return restApi;
};

const getJobs = async () => {
  try {
    const response = await getRestApi().get("/analyses");
    const data = parseResponse(response);
    return okResult(data as AnalysisTask[]);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getJob = async (id: string) => {
  try {
    const response = await getRestApi().get(`/analyses/${id}`);
    const data = parseResponse(response);
    return okResult(data as AnalysisTask);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const uploadWorksheet = async (formData: FormData) => {
  try {
    const response = await getRestApi().post("/upload-new-worksheet", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = parseResponse(response);
    return okResult(data); // TODO
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const parseResponse = (response: AxiosResponse) => {
  if (![200, 201].includes(response.status)) {
    throw Error(response.statusText);
  }
  if (!response.data) {
    return [];
  }
  let data = response.data;
  if (typeof data !== "object") {
    data = [];
  }
  return data;
};

const okResult = <T>(data: T): { data: T; error: null } => ({
  data,
  error: null,
});

const errorResult = (error: unknown): { data: null; error: CustomError } => ({
  data: null,
  error:
    isAxiosError(error) && error.response
      ? {
          // Try to get custom errors, fall back to Axios errors
          error: error.response.data.error ?? error.response.statusText,
          msg: error.response.data.msg ?? error.message,
        }
      : {
          error: "Error",
          msg: "",
        },
});

export const dataService = {
  getJobs,
  getJob,
  uploadWorksheet,
};
